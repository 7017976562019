










import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class CamiloDetail extends Vue {
  @Prop() readonly html!: string;
  @Prop() readonly title!: string;

  HandlePictureEvents(): void {
    const el_ = document.querySelectorAll(".__camilo_detail .image-wrap img");
    const images: string[] = [];
    el_.forEach((el) => images.push(el.getAttribute("src")!));
    el_.forEach((el, i) => {
      el.addEventListener("click", function () {
        wx.previewImage({
          current: images[i],
          urls: images,
        });
      });
    });
  }

  HandleMask(): void {
    // 处理蒙层
    document.querySelectorAll(".__camilo_detail .mask").forEach((el) => {
      el.addEventListener("click", (e) => {
        (e.target as HTMLDivElement).classList.remove("mask");
        (e.target as HTMLDivElement).removeEventListener("click", () => {});
      });
    });
  }

  async HandleTemplate() {
    const templateDomList = document.querySelectorAll(".template");
    let ids = Array.prototype.map.call(templateDomList, (el) =>
      el.getAttribute("templateid")
    );
    ids = Array.from(new Set(ids));
    if (!ids.length) return;
    const templateList = await Promise.all(
      ids.map((id) =>
        fetch(`${process.env.SITE_CONFIG.BASE_URL}/template/list?id=${id}`)
          .then((res) => res.json())
          .then((res) => {
            // console.log(res);
            if (res.data.total === 0) {
              return {
                id,
                html: "",
              };
            }
            return res.data.data[0];
          })
      )
    );
    templateDomList.forEach((el) => {
      const id = el.getAttribute("templateid");
      el.innerHTML =
        templateList.find((val) => Number(val.id) === Number(id)).html ?? "";
    });
  }

  mounted(): void {
    this.HandlePictureEvents();
    this.HandleMask();
    document
      .querySelectorAll("iframe")
      .forEach((e) => (e.src = e.getAttribute("data-src") || ""));
    this.HandleTemplate();
  }
}
