

















































import { Component, Model, Vue, Emit } from "vue-property-decorator";
import { getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({})
export default class SyntheticDialog extends Vue {
  @Model("update:show", { default: false })
  private readonly show!: boolean;

  @Emit("update:show")
  handleUpdateShow(show: boolean): boolean {
    return show;
  }

  handleUpdate(): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/synthetic`);
    }
    this.$router.push({ name: "Synthetic", query: { time: String(getNow()) } });
    this.handleUpdateShow(false);
  }

  handleChoose(): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/optional`);
    }
    this.$router.push({ name: "optional" });
    this.handleUpdateShow(false);
  }

  handledemand(): void {
    this.handleUpdateShow(false);
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sell`);
    }
    this.$router.push({ name: "sell" });
  }
}
